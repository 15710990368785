<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
          <!-- <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="gradient-primary"
            block
            @click="addData"
          >
            เพิ่มโปรโมชั่น
          </b-button> -->
          </b-form-group>
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(pic)="data">
          <img
            height="100"
            :src="data.item.pic"
            class=" rounded"
          >
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
            <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="deleteData(data.item.id)"
          /> -->
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(enable)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="data.item.status"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchEnable(data.item.id,data.item.status)"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="แก้ไขโปรโมชั่น"
        ok-title="Submit"
        @ok="submit"
      >
        <b-form-group
          label="ชื่อโปรโมชั่น"
          label-for="vi-first-name"
        >
          <b-form-input
            id="vi-first-name"
            v-model="name_show"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="รายละเอียดโปรโมชั่น"
          label-for="vi-first-name"
        >
          <b-form-textarea
            id="vi-first-name"
            v-model="detail_show"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="% โบนัส"
          label-for="vi-first-name"
        >
          <b-form-input
            id="vi-first-name"
            v-model="percent"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="เทิร์น"
          label-for="vi-first-name"
        >
          <b-form-input
            id="vi-first-name"
            v-model="turn"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="โบนัสสูงสุด ( บาท )"
          label-for="vi-first-name"
        >

          <b-form-input
            id="vi-first-name"
            v-model="max_bonus"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="ถอนได้สูงสุด ( เท่า )"
          label-for="vi-first-name"
        >

          <b-form-input
            id="vi-first-name"
            v-model="max_withdraw"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="แก้ไขรูปโปรฯ ( 600 x 600 )"
          label-for="h-files-name"
        >
          <b-form-file
            v-model="pic"
            @change="handleFileUpload"
          />
          <br><br>
          <div><img
            height="100"
            :src="pic"
          ></div>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BOverlay, BIconController,
  BModal, BFormTextarea, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle, BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,
    BModal,
    BFormFile,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormCheckbox,
    BFormTextarea,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      checked: false,
      depositdata: [],
      DataEditID: '',
      name: '',
      detail: '',
      turn: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'ลำดับ' },
        { key: 'pic', label: 'รูปโปร' },
        { key: 'name_show', label: 'โปรโมชั่น' },
        { key: 'detail_show', label: 'รายละเอียด' },
        { key: 'percent', label: 'รับโบนัส %' },
        { key: 'turn', label: 'เทิร์น' },
        { key: 'max_bonus', label: 'โบนัสสูงสุด' },
        { key: 'max_withdraw', label: 'ถอนได้สูงสุด' },

        { key: 'enable', label: 'เปิด/ปิด' },
        { key: 'actions', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      pic: '',
      selectedFile: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.getBank()
  },
  methods: {
    switchEnable(id, data) {
      const obj = {
        id,
        status: data,
      }
      // console.log(obj)
      this.$http
        .post('/promotion/status', obj)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    handleFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]
      if (this.selectedFile) {
        const reader = new FileReader()
        reader.onload = () => {
          const base64Image = reader.result
          // Send the base64Image to the API using an HTTP request
          // Example:
          this.pic = base64Image
        }
        reader.readAsDataURL(this.selectedFile)
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    getBank() {
      this.show = true
      this.$http
        .get('/promotion/index')
        .then(response => {
          this.onFiltered(response.data)
          this.show = false
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      for (let i = 0; i < filteredItems.length; i += 1) {
        if (filteredItems[i].agent_use) {
          const x = filteredItems[i].agent_use.split(',')
          if (x.indexOf(this.UserData.agent_id.toString()) !== -1) {
          // eslint-disable-next-line no-param-reassign
            filteredItems[i].status = true
          } else {
          // eslint-disable-next-line no-param-reassign
            filteredItems[i].status = false
          }
        }
      }
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getAdmin(addby, index) {
      this.$http
        .get(`/admin/show/${addby}`)
        .then(response => {
          console.log(response.data.name)
          this.depositdata[index].addby = response.data.name
        })
        .catch(error => console.log(error))
    },
    submit() {
      const formData = {
        name_show: this.name_show,
        detail_show: this.detail_show,
        turn: this.turn,
        percent: this.percent,
        max_bonus: this.max_bonus,
        max_withdraw: this.max_withdraw,
        pic: this.pic,
      }
      if (this.DataEditID) {
        this.$http
          .post(`/promotion/updateV2/${this.DataEditID}`, formData)
          .then(() => {
            this.getBank()
            this.name_show = ''
            this.detail_show = ''
            this.turn = ''
            this.percent = ''
            this.max_bonus = ''
            this.max_withdraw = ''
            this.pic = ''
            this.Success('update สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('/promotion/store', formData)
          .then(() => {
            this.getBank()
            this.name = ''
            this.detail = ''
            this.turn = ''
            this.Success('เพิ่มโปรโมชั่น สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/promotion/delete/${id}`)
            .then(response => {
              this.getBank()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('Cancelled', 'not been deleted !', 'error')
        }
      })
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.name = ''
      this.detail = ''
      this.turn = ''
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // console.log(data)
      this.DataEditID = data.id
      this.turn = data.turn
      this.max_bonus = data.max_bonus
      this.max_withdraw = data.max_withdraw
      this.percent = data.percent
      this.name_show = data.name_show
      this.detail_show = data.detail_show
      this.pic = data.pic
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
